<template>
  <main>
    Mitmenschen
  </main>
</template>

<script>
export default {
  name: 'PageFellowHumans',
}
</script>
